import React from 'react'
import * as myStyles from './styles.module.scss'
import HeroModule from '../../lib/components/modules/hero'
import {
    heroProps,
    preFooterTitle,
    cardsDataPrefooter,
    flexibleTextBlock,
    flexibleTextBlock2,
} from './constants'
import PreFooter from '../../lib/components/modules/prefooter'
import FlexibleModule from '../../lib/components/modules/flexible'

const TryNowPage = () => {
    const styles: any = myStyles

    return (
        <div className={styles.try_now_page}>
            <div className={styles.content}>
                <HeroModule {...heroProps} isLight={true} />
                <div className={styles.bg_grey_black} data-background={'dark'}>
                    <FlexibleModule
                        textBlocks={[flexibleTextBlock]}
                        childPosition={'right'}
                        image={flexibleTextBlock.image}
                    />
                </div>
                <div className={styles.bg_grey_98} data-background={'light'}>
                    <FlexibleModule
                        textBlocks={[flexibleTextBlock2]}
                        childPosition={'left'}
                        image={flexibleTextBlock2.image}
                    />
                </div>
                <div className={styles.bg_grey_20}>
                    <PreFooter title={preFooterTitle} cardsData={cardsDataPrefooter} />
                </div>
            </div>
        </div>
    )
}

export default TryNowPage
