import React, { Fragment } from 'react'
import Layout from '../components/Layout'
import TryNowPage from '../hanzo/pages/try-now'
import { useStaticQuery, graphql } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'
import { Helmet } from 'react-helmet'

const RootIndex: React.FC = () => {
    const data = useStaticQuery(graphql`
        query TryNowSEO {
            allContentfulPages(filter: { pageReference: { pageIdentifier: { eq: "Box" } } }) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    return (
        <Fragment>
            <Layout newsletter={true} linkColor={'dark'}>
                <HeaderSEO pageSEO={pageSEO} />
                <Helmet>
                    <link rel='canonical' href='https://lenses.io/contact-us/' />
                </Helmet>
                <section>
                    <TryNowPage />
                </section>
            </Layout>
        </Fragment>
    )
}

export default RootIndex
