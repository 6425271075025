import React from 'react'

// // Video for flexibleMediaContent
import governVideoFeature from '../../../resources/images/hanzo/features/01_govern.mp4'
import { DemoIcon, PricingPlansIcon } from '../../lib/components/basics/icons'

// // Image for flexible text blocks
import whatsInTheBox from '../../../resources/images/hanzo/try-now/whats-in-the-box.svg'
import loginScreen from '../../../resources/images/hanzo/try-now/login-screen.svg'

// HERO PROPERTIES (Used in HeroModule component)
export const heroProps = {
    pretitle: 'DOWNLOAD FREE',
    title: 'Lenses Community + Apache Kafka Docker Compose',
    description: (
        <>
            <p>
                A complete environment to discover, process, integrate and govern data streams for
                free on your laptop:
            </p>
            <pre>
                <code>
                    wget https://lenses.io/preview -O docker-compose.yml
                    <br />
                    ACCEPT_EULA=true docker compose up
                </code>
            </pre>
            <br />
            <p>
                You can visit Lenses HQ at <a href='http://localhost:9991'>http://localhost:9991</a>
            </p>
        </>
    ),
}

// FEATURES TITLE (Used in FixedTitle component)
export const featuresTitle = 'Let developers do their thing'

// PREFOOTER (Used in PreFooter component)
export const preFooterTitle = 'See how Lenses works for you in production'

// Text blocks for flexible module (Used in FlexibleModule component)
export const flexibleTextBlock = {
    title: 'What’s included?',
    description: `
            <ul>
                <li>Lenses 6 HQ — Community Edition</li>
                <li>Lenses 6 Agent</li>
                <li>Apache Kafka Broker</li>
                <li>Kafka Connect with Lenses Connector plugins installed</li>
                <li>Schema Registry</li>
                <li>Pre-loaded data generators to demo Lenses</li>
                <li>PostgreSQL</li>
            </ul>`,
    image: {
        imgSrc: whatsInTheBox,
        alt: 'what`s in the box',
    },
}

export const flexibleTextBlock2 = {
    title: 'Start using Lenses',
    description: `
            <ul>
                <li>Run the docker command: <br />
                    <pre><code>wget https://lenses.io/preview -O docker-compose.yml<br/>ACCEPT_EULA=true docker compose up</code></pre>
                </li>
                <li>Open the Lenses HQ at <a href='http://localhost:9991'>http://localhost:9991</a></li>
                <li>Log in with <i>admin / admin</i></li>
                <li>Click on the first environment to try Lenses with synthetic data</li>
                <li>Add up to 2 Kafka clusters, and 2 users</li>
            </ul>
            <br />
            <p>Want to use Lenses with more than 2 Kafka clusters?</p>`,
    image: {
        imgSrc: loginScreen,
        alt: 'login screen',
    },
    ctas: [
        {
            href: '/request-demo',
            text: 'Get a demo',
        },
    ],
}

// CARDS DATA FOR PREFOOTER (Used in PreFooter component)
export const cardsDataPrefooter = [
    {
        title: 'Simple scalable plans',
        type: 'primary' as const,
        description: [],
        buttonText: 'See pricing',
        href: '/product/pricing/',
        icon: <PricingPlansIcon key='pricingIcon' />,
    },
    {
        title: '20 minutes<br />demo,<br />7 days trial',
        type: 'secondary' as const,
        description: [
            'Guided tour of Lenses',
            'Trial key to install on your clusters',
            'Great for production and large-scale Kafka deployments',
        ],
        buttonText: 'Get a demo',
        href: '/request-demo',
        icon: <DemoIcon key='demo Icon' />,
    },
]

// FLEXIBLE VIDEOS

export const flexibleVideos = [
    {
        title: 'Create a Kafka topic in seconds',
        description: `
            <ul>
            <li>Instructions</li>
            <li>Instructions</li>
            <li>Instructions</li>
            </ul>`,
    },
    {
        title: 'Discover data streams in a catalog',
        description: `
            <ul>
            <li>Instructions</li>
            <li>Instructions</li>
            <li>Instructions</li>
            </ul>`,
    },
    {
        title: 'Query streams using SQL',
        description: `
            <ul>
            <li>Instructions</li>
            <li>Instructions</li>
            <li>Instructions</li>
            </ul>`,
    },
    {
        title: 'Build a stream processing app',
        description: `
            <ul>
            <li>Instructions</li>
            <li>Instructions</li>
            <li>Instructions</li>
            </ul>`,
    },
]

export const flexibleVideosMediaContent = [
    {
        video: {
            src: governVideoFeature,
        },
    },
    {
        video: {
            src: governVideoFeature,
        },
    },
    {
        video: {
            src: governVideoFeature,
        },
    },
    {
        video: {
            src: governVideoFeature,
        },
    },
]
