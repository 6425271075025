import React from 'react'
import { FlexibleGridProps } from './types'
import * as myStyles from './styles.module.scss'
import FlexibleTextBlock from '../../components/flexibleTextBlock'

const FlexibleModule: React.FC<FlexibleGridProps> = ({
    textBlocks = [],
    image,
    children,
    childPosition = 'right',
    video,
    hasBorder = true,
}) => {
    const styles: any = myStyles

    const hasTextBlocks = textBlocks.length > 0
    const hasMedia = Boolean(image) || Boolean(video)
    const hasChildren = Boolean(children)

    return (
        <div
            className={styles.m_flexible_module}
            data-child-position={childPosition}
            data-has-border={hasBorder}>
            {hasTextBlocks && !hasMedia && !hasChildren && (
                <div className={styles.gridThreeColumns}>
                    {textBlocks.map((textProps, index) => (
                        <div key={index} className={styles.textBlock}>
                            <FlexibleTextBlock key={index} {...textProps} />
                        </div>
                    ))}
                </div>
            )}
            {hasTextBlocks && (hasMedia || hasChildren) && (
                <div className={styles.gridTwoColumns}>
                    <div className={styles.contentBlock}>
                        {hasChildren ? (
                            <div className={styles.children}>{children}</div>
                        ) : (
                            <div className={styles.figure}>
                                {image ? (
                                    <img src={image.imgSrc} alt={image.altText} />
                                ) : (
                                    video && (
                                        <video
                                            className={styles.video}
                                            autoPlay={true}
                                            loop={video.loop || true}
                                            muted={video.muted || true}>
                                            <source src={video.src} type='video/mp4' />
                                        </video>
                                    )
                                )}
                            </div>
                        )}
                    </div>
                    <div className={styles.textBlock}>
                        <FlexibleTextBlock {...textBlocks[0]} />
                    </div>
                </div>
            )}
        </div>
    )
}

export default FlexibleModule
